import React from 'react';
import { capitalizeEachWord } from '@utils/strings';
import { Heading } from '@components/typography';
import styles from './CollectionSectionHeader.module.scss';

type CollectionSectionHeaderProps = {
	heading: string;
};

const CollectionSectionHeader = ({ heading }: CollectionSectionHeaderProps) => {
	return (
		<div className={styles.container}>
			<Heading className={styles.heading} tag='h6'>
				{capitalizeEachWord(heading)}
			</Heading>
		</div>
	);
};

export default CollectionSectionHeader;
