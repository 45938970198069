import { FC } from 'react';
import { BASE_FRAME_NAMES } from '@constants';
import { FrameShapeSelect } from '@components';
import styles from './FilterRow.module.scss';
interface FilterRowProps {
	withFrameShape?: boolean;
	updateFrameShape?: (f: typeof BASE_FRAME_NAMES[number]) => void;
}

const FilterRow: FC<FilterRowProps> = ({
	withFrameShape = false,
	updateFrameShape,
}) => {
	return (
		<div className={styles.container}>
			<div className={withFrameShape ? styles.inner : styles.innerReverse}>
				{withFrameShape && (
					<div className={styles.selectContainer}>
						<FrameShapeSelect callback={updateFrameShape} />
					</div>
				)}
			</div>
		</div>
	);
};

export default FilterRow;
