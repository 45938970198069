import { memo, useMemo, useRef, useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import { PaginationOptions } from 'swiper/types';
import cn from 'classnames';
import { Chevron, Hero, Pause, Play } from '@components';
import { useIsWKWebView } from '@utils/check-for-wkwebview';
import { useHasMounted } from '@utils/hooks';
import styles from './HeroCarousel.module.scss';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

const HeroCarousel = ({ componentType, height, page, slides, frameShape = null, asPlp = false, effect = 'fade' }) => {
	const hasMounted = useHasMounted();
	const isWKWebView = useIsWKWebView()
	const [isPausing, setIsPausing] = useState(isWKWebView);
	const swiperRef = useRef(null);
	const nextElRef = useRef(null);
	const prevElRef = useRef(null);

	const customPagination = {
		pagination: {
			clickable: true,
			type: 'bullets',
			bulletElement: 'button',
			el: '.swiper-pagination',
			bulletClass: cn(styles['swiper-pagination-bullet'], { [styles['plp']]: asPlp }),
			bulletActiveClass: styles['swiper-pagination-bullet-active'],
			lockClass: styles['swiper-pagination-lock'],
			clickableClass: styles['swiper-pagination-clickable'],
		} as PaginationOptions,
	};

	const handleClick = () => {
		setIsPausing(!isPausing);
		if (swiperRef.current && swiperRef.current.swiper) {
			if (isPausing) {
				swiperRef.current.swiper.autoplay.start();
			} else {
				swiperRef.current.swiper.autoplay.stop();
			}
		}
	};

	const handleNavigation = useCallback(
		action => {
			if (!swiperRef.current) return;
			const swiperInstance = swiperRef.current.swiper;

			switch (action) {
				case 'prev':
					swiperInstance.slidePrev();
					break;
				case 'next':
					swiperInstance.slideNext();
					break;

				default:
					break;
			}
		},
		[swiperRef]
	);

	const slidesMemo = useMemo(
		() =>
			slides?.map((block, index) => {
				return (
					<SwiperSlide className={`heroSlide-${index}`} key={`hero-carousel-slide-${index}`}>
						<Hero {...block} slideIndex={index} frameShape={frameShape} />
					</SwiperSlide>
				);
			}),
		[frameShape, slides]
	);

	return (
		<Swiper
			ref={swiperRef}
			loop
			autoplay={isWKWebView ? false : { delay: 4000 }}
			direction='horizontal'
			effect={effect}
			pagination={customPagination.pagination}
			modules={[Autoplay, EffectFade, Pagination, Navigation]}
			navigation={{ prevEl: prevElRef.current, nextEl: nextElRef.current }}
			speed={600}
			height={Number(height)}
			className={cn('heroContainer', styles.container, {
				[styles['plp-carousel']]: asPlp,
			})}
			data-component-type={componentType}
			data-page-type={page}
		>
			{slidesMemo}
			<button
				ref={prevElRef}
				className={`heroPrevSlideButton ${styles['swiper-button-prev']}`}
				onClick={() => handleNavigation('prev')}
				aria-label='previous slide'
			>
				<Chevron direction='left' />
			</button>
			<button
				ref={nextElRef}
				className={`heroNextSlideButton ${styles['swiper-button-next']}`}
				onClick={() => handleNavigation('next')}
				aria-label='next slide'
			>
				<Chevron direction='right' />
			</button>
			<div
				className={cn('swiper-pagination', styles['swiper-pagination'])}
			/>
			{hasMounted &&
				<button className={`heroPlayPauseButton ${styles['swiper-play-button']}`} onClick={handleClick} aria-label='next slide' data-testid='pause-btn'>
					{isPausing ? <Play /> : <Pause />}
				</button>
			}
		</Swiper>
	);
};

export default memo(HeroCarousel);
