import React from 'react';
import { Text } from '@contentful/rich-text-types';
import { MarketingCardFields } from '@ts/contentful';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { InteractiveCard, VerticalCard } from '@components';
import { useIsMobile } from '@utils/hooks';
import { PRODUCT_TYPES } from '@constants';
import styles from './ProductAndBlockGrid.module.scss';

type ProductAndBlockGridProps = {
	variants: NormalizedVariant[];
	cards: MarketingCardFields[];
	parentInfo: {
		parentCollectionHandle: string;
		usingSingleColumnLayout: boolean;
	}
};

const ProductAndBlockGrid = ({ variants, cards, parentInfo }: ProductAndBlockGridProps) => {
	const isMobile = useIsMobile();
	const cardLocations = cards.map(card => card.blockLocation - 1)
	const cardsByLocation = cards.reduce((acc, card) => {
		const isPlpGetStarted = card.title?.includes('Get Started');
		acc[card.blockLocation - 1] = <InteractiveCard
			data-marketing-block={card.title}
			background={card.background.url}
			title={card.title}
			text={(card.text?.content[0].content[0] as Text)?.value}
			subtitle={card.subtitle}
			subheading={!card.shouldExpand ? (card.text?.content[0].content[0] as Text)?.value : ""}
			shouldExpand={card.shouldExpand}
			shouldDisplayTitle={card.shouldDisplayTitle}
			button1={{
				label: card.buttonLabel1,
				link: card.buttonLink1,
				color: card.buttonColor1,
			}}
			{...(card.buttonLabel2 && {
				button2: {
					label: card.buttonLabel2,
					link: card.buttonLink2,
					color: card.buttonColor2,
				},
			})}
			asPlp
			shouldFixHeight={(isMobile && (card?.size === 'micro' || isPlpGetStarted)) ?? false}
			className={parentInfo.usingSingleColumnLayout ? styles['interactive-card'] : styles['interactive-card-double']}
			data-size-block={card.size}
		/>
		return acc
	}, {} as Record<number, JSX.Element>)

	return (
		<>
			{variants.map((variant, index) => (
				<React.Fragment key={`block-${index}`}>
					{cardLocations.some(location => location === index) && (
						cardsByLocation[index]
					)}
					<VerticalCard
						key={variant.handle}
						product={variant.product as NormalizedProduct}
						variant={variant}
						primaryAction={variant.type === PRODUCT_TYPES.GIFT_CARD ? 'view' : 'cart'}
						secondaryAction='favorite'
						parentCollectionHandle={parentInfo.parentCollectionHandle}
						compact={parentInfo.usingSingleColumnLayout ? false : isMobile}
						showCollectionLozenge={false}
						dataTags={
							{
								'button': { 'data-add-to-cart-from-plp': true },
								'zoom': {},
								'favorite': { 'data-add-favorite-from-plp': true },
							}}
					/>
				</React.Fragment>
			))}
		</>
	);
};

export default ProductAndBlockGrid;
