import { memo } from 'react';
import { Hero, InteractiveCard, TopFrameHeading } from '@components';
import { ContentfulImage, HeroFields, NormalizedCollection } from '@ts/index';
import styles from './CollectionHero.module.scss';

type CollectionHeroProps = {
	collection: NormalizedCollection;
	ignoreCopy?: boolean;
	useBigHero?: boolean;
};

const CollectionHero = ({ collection, ignoreCopy = false, useBigHero = false }: CollectionHeroProps) => {
	const { title, copy, color } = collection?.metafields ?? {};

	return (
		<>
			<InteractiveCard
				asHero
				useBigHero={useBigHero}
				background={collection.metafields?.collectionBannerVideo?.previewImage?.url ?? collection?.image?.url}
				backgroundMobile={collection?.metafields?.mobileImage?.url || null}
				title={ignoreCopy ? null : collection?.title}
				text={copy}
				subtitle={title}
				hideTitleWhenClosed={!!collection.metafields.smallCollectionLogo}
				shouldExpand={false}
				solidBackgroundColor={!collection?.image ? color : null}
				copyrightInfo={collection.metafields?.licensingLegalCopy}
				sourcesVideo={collection.metafields?.collectionBannerVideo?.sources ?? []}
				extraHeaderClassName={styles['extra-header']}
			>
				{collection?.image && collection.metafields.smallCollectionLogo ? (
					<Hero {...{
						imageDesktop: { ...collection?.image } as ContentfulImage,
						imageMobile: { ...(collection?.metafields?.mobileImage || collection?.image) } as ContentfulImage,
						type: 'Full-Bleed',
						asBanner: true,
						size: (useBigHero) ? 'medium' : 'responsive',
					} as HeroFields}>
						<TopFrameHeading
							hasButton
							title={collection.title}
							logo={collection.metafields.smallCollectionLogo}
							isRightAlign={collection.handle === 'van-gogh'}
						/>
					</Hero>
				) : null}
			</InteractiveCard>
		</>
	)
};

export default memo(CollectionHero);
